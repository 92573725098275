import Logo from "src/components/common/GlobalHeader/Logo"

export const GlobalHeaderLogoOnly: React.FC = () => {
  return (
    <>
      <header className="global-header">
        <div className="global-header-inner">
          <Logo linkTo="/" />
        </div>
      </header>
    </>
  )
}
