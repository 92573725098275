import React from "react"

import routes from "routes"
import { useRouter } from "next/router"
import { useUser } from "src/utils/auth/useUser"

// view
import { AuthList } from "src/components/common/GlobalHeader/AuthList"
import AvatarButton from "src/components/common/GlobalHeader/AvatarButton"
import { GlobalPcMenu } from "src/components/common/GlobalHeaderForReader/GlobalPcMenu"
import { GlobalSpMenuForReader } from "src/components/common/GlobalSpMenuForReader"
import { IconNav } from "src/components/common/GlobalHeaderForReader/IconNav"
import { HunburgerButton } from "src/components/common/GlobalHeader/HunburgerButton"
import { useHeaderManage } from "src/components/hooks/useHeaderManage"
import { HeaderSearch } from "src/components/common/GlobalHeader/HeaderSearch"

const HeaderContents: React.FC = () => {
  const {
    globalPcHeaderMenuVisible,
    globalSpHeaderMenuVisible,
    handleClickMenuToggel,
    handlePcMenuClose,
    handleSpMenuClose,
    handleLogout,
  } = useHeaderManage()
  const { isMember, clientState } = useUser()
  const router = useRouter()

  if (clientState === null) return null

  if (isMember) {
    return (
      <>
        <HeaderSearch />
        <div className="global-header-avatar">
          <AvatarButton onClick={() => router.push(routes.mypage())} />
        </div>
        <IconNav />
        <div>
          <HunburgerButton handleClick={handleClickMenuToggel} />
          <GlobalPcMenu
            visible={globalPcHeaderMenuVisible}
            handleClose={handlePcMenuClose}
            handleLogout={handleLogout}
          />
          <GlobalSpMenuForReader
            visible={globalSpHeaderMenuVisible}
            handleClose={handleSpMenuClose}
            handleLogout={handleLogout}
          />
        </div>
      </>
    )
  } else {
    return <AuthList />
  }
}

export default HeaderContents
