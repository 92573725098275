import HeaderContents from "src/components/common/GlobalHeaderForReader/HeaderContents"
import Logo from "src/components/common/GlobalHeader/Logo"

export const GlobalHeaderForReader: React.FC = () => {
  return (
    <>
      <header className="global-header">
        <div className="global-header-inner">
          <Logo linkTo="/" />
          <div className="global-header-contents">
            <HeaderContents />
          </div>
        </div>
      </header>
    </>
  )
}
