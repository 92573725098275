import Head from "next/head"
import siteMeta from "src/config/siteMeta"

type Props = {
  path?: string
  title?: string
}

const HTMLHead: React.FC<Props> = ({ path = "", title }) => {
  const meta = siteMeta(path, title)

  return (
    <Head>
      <title>{meta.title}</title>
    </Head>
  )
}

export default HTMLHead
