import clsx from "clsx"
import { isNull } from "lodash"
import React from "react"
import { useUnmount } from "react-use"
import { useUser } from "src/utils/auth/useUser"

// view
import { NavList } from "src/components/common/GlobalSpMenuForReader/NavList"
import TieupImg from "src/components/common/TieupImg"
import { useMyAvatarSrc } from "../../hooks/useMyAvatarSrc"
import Link from "next/link"
import routes from "routes"

interface Props {
  visible: boolean
  handleClose: () => void
  handleLogout: () => void
}

export const GlobalSpMenuForReader: React.FC<Props> = ({
  visible,
  handleClose,
  handleLogout,
}) => {
  const avatarSrc = useMyAvatarSrc()
  const { apiUser, clientState } = useUser()

  useUnmount(() => {
    handleClose()
  })

  return (
    <>
      <nav className={clsx({ "global-nav": true, "is-show": visible })}>
        <div className="global-nav-change-button-wrapper">
          <Link href={routes.creator()}>
            <a target="_blank" className="global-nav-change-button">
              作品を投稿する
            </a>
          </Link>
        </div>
        <button
          className="c-close-button global-nav-close"
          onClick={handleClose}
        >
          <span className="c-close-button-icon"></span>
          <span className="c-close-button-label">閉じる</span>
        </button>
        <div className="global-nav-inner">
          <div className="global-nav-avatar">
            <div className="global-nav-avatar-image">
              <TieupImg src={avatarSrc} alt={apiUser?.name} />
            </div>
            <div className="global-nav-avatar-text">
              {apiUser && (
                <>
                  <p className="global-nav-avatar-name">{apiUser?.name}</p>
                  <p className="global-nav-avatar-id">
                    {apiUser?.display_name}
                  </p>
                </>
              )}
            </div>
          </div>
          <NavList />
          {!isNull(clientState) && (
            <button className="global-nav-logout" onClick={handleLogout}>
              ログアウト
            </button>
          )}
        </div>
      </nav>
      <div
        className={clsx({ "global-nav-overlay": true, "is-show": visible })}
        onClick={handleClose}
      />
    </>
  )
}
