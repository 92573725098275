export const siteInfo = {
  title: "たいあっぷ | イラスト・小説コラボ型投稿サイト",
  description:
    "表紙、口絵、挿絵付きライトノベルが無料で読める。新しいプラットフォーム。",
}

const createTitle = (title?: string) => {
  if (title) {
    return `${title}`
  }

  return "たいあっぷ | イラスト・小説コラボ型投稿サイト"
}

const siteMeta = (key: string, title?: string) => {
  switch (key) {
    case "auth/sing-in": {
      return {
        title: createTitle("サインイン"),
      }
    }
    case "auth/sing-up": {
      return {
        title: createTitle("新規会員登録"),
      }
    }
    case "auth/password-new": {
      return {
        title: createTitle("パスワードの再設定"),
      }
    }
    case "auth/change-login-email": {
      return {
        title: createTitle("ログイン用メールアドレスの変更"),
      }
    }
    case "auth/change-notification-email": {
      return {
        title: createTitle("通知用メールアドレスの変更"),
      }
    }
    case "auth/change-password": {
      return {
        title: createTitle("パスワードの変更"),
      }
    }
    case "auth/create-profile": {
      return {
        title: createTitle("新規会員登録"),
      }
    }
    case "auth/privacy-settings": {
      return {
        title: createTitle("プライバシー設定"),
      }
    }
    case "creator/mypage": {
      return {
        title: createTitle("マイページ"),
      }
    }
    case "creator/mypage/novels": {
      return {
        title: createTitle("小説一覧"),
      }
    }
    case "creator/mypage/illustrations": {
      return {
        title: createTitle("イラスト一覧"),
      }
    }
    case "creator/mypage/tieup-requests": {
      return {
        title: createTitle("たいあっぷ申請一覧"),
      }
    }
    case "creator/mypage/tieup-requests-detail": {
      return {
        title: createTitle("たいあっぷ申請"),
      }
    }
    case "creator/mypage/tieup-detail": {
      return {
        title: createTitle("たいあっぷ作品管理"),
      }
    }
    case "creator/mypage/tieup-detail-edit": {
      return {
        title: createTitle("基本情報"),
      }
    }
    case "creator/mypage/tieup-detail-data": {
      return {
        title: createTitle("作品データ"),
      }
    }
    case "creator/mypage/tieup-detail-applications": {
      return {
        title: createTitle("作品申請関連"),
      }
    }
    case "creator/mypage/tieup-detail-members": {
      return {
        title: createTitle("たいあっぷ参加者一覧"),
      }
    }
    case "creator/mypage/tieup-series": {
      return {
        title: createTitle("シリーズ作品管理"),
      }
    }
    case "creator/mypage/tieup-detail-outline": {
      return {
        title: createTitle("あらすじ"),
      }
    }
    case "creator/mypage/tieup-detail-notes": {
      return {
        title: createTitle("共有メモ一覧"),
      }
    }
    case "creator/mypage/tieup-detail-notes-detail": {
      return {
        title: createTitle("共有メモ"),
      }
    }
    case "creator/mypage/tieup-detail-notes-new": {
      return {
        title: createTitle("共有メモ 新規作成"),
      }
    }
    case "creator/mypage/tieup-detail-notes-edit": {
      return {
        title: createTitle("共有メモ 編集"),
      }
    }
    case "creator/mypage/novel-detail-notes-edit": {
      return {
        title: createTitle("共有メモ 編集"),
      }
    }
    case "creator/mypage/tieup-detail-characters": {
      return {
        title: createTitle("キャラリスト"),
      }
    }
    case "creator/mypage/tieup-detail-characters-detail": {
      return {
        title: createTitle("キャラクター詳細"),
      }
    }
    case "creator/mypage/tieup-detail-characters-edit": {
      return {
        title: createTitle("キャラクター編集"),
      }
    }
    case "creator/mypage/tieup-detail-characters-new": {
      return {
        title: createTitle("キャラクター新規作成"),
      }
    }
    case "creator/mypage/tieup-detail-public-management": {
      return {
        title: createTitle("公開設定"),
      }
    }
    case "creator/mypage/tieup-chapter-new": {
      return {
        title: createTitle("新規章登録"),
      }
    }
    case "creator/mypage/tieup-chapter-edit": {
      return {
        title: createTitle("章編集"),
      }
    }
    case "creator/mypage/edit-profile": {
      return {
        title: createTitle("プロフィール編集"),
      }
    }
    case "creator/user": {
      return {
        title: createTitle(`${title} | クリエイター`),
      }
    }
    case "creator": {
      return {
        title: createTitle(`クリエイター検索`),
      }
    }
    case "creator/tieup": {
      return {
        title: createTitle(`最近たいあっぷした作品`),
      }
    }
    case "creator/search/novels": {
      return {
        title: createTitle(`小説検索結果一覧 | クリエイター検索`),
      }
    }
    case "creator/novels": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "creator/mypage/novel-index": {
      return {
        title: createTitle("小説管理"),
      }
    }
    case "creator/mypage/novel-public-management": {
      return {
        title: createTitle("公開設定"),
      }
    }
    case "creator/mypage/novel-new": {
      return {
        title: createTitle("小説新規作成"),
      }
    }
    case "creator/mypage/novel-chapter-new": {
      return {
        title: createTitle("新規章登録"),
      }
    }
    case "creator/mypage/novel-chapter-edit": {
      return {
        title: createTitle("章編集"),
      }
    }
    case "creator/mypage/novel-detail-edit": {
      return {
        title: createTitle("基本情報"),
      }
    }
    case "creator/mypage/novel-detail-outline": {
      return {
        title: createTitle("あらすじ"),
      }
    }
    case "creator/mypage/novel-characters": {
      return {
        title: createTitle("キャラリスト"),
      }
    }
    case "creator/mypage/novel-characters-new": {
      return {
        title: createTitle("キャラクター新規作成"),
      }
    }
    case "creator/mypage/novel-characters-detail": {
      return {
        title: createTitle("キャラクター詳細"),
      }
    }
    case "creator/mypage/novel-characters-edit": {
      return {
        title: createTitle("キャラクター編集"),
      }
    }
    case "creator/mypage/novel-notes": {
      return {
        title: createTitle("共有メモ"),
      }
    }
    case "creator/mypage/novel-notes-new": {
      return {
        title: createTitle("共有メモ新規作成"),
      }
    }
    case "creator/mypage/novel-notes-detail": {
      return {
        title: createTitle("共有メモ詳細"),
      }
    }
    case "creator/mypage/novel-notes-edit": {
      return {
        title: createTitle("共有メモ編集"),
      }
    }
    case "creator/mypage/notification-settings": {
      return {
        title: createTitle("通知設定"),
      }
    }
    case "creator/mypage/illust-new": {
      return {
        title: createTitle("イラスト新規作成"),
      }
    }
    case "creator/mypage/illust-detail": {
      return {
        title: createTitle("イラスト詳細"),
      }
    }
    case "creator/mypage/illust-public-management": {
      return {
        title: createTitle("イラスト公開管理"),
      }
    }
    case "novels": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "novels/series": {
      return {
        title: createTitle("シリーズ作品"),
      }
    }
    case "creator/illustrations": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "creator/search/genres": {
      return {
        title: createTitle(`ジャンル検索 | クリエイター検索`),
      }
    }
    case "creator/search/illustrations": {
      return {
        title: createTitle(`イラスト検索結果一覧 | クリエイター検索`),
      }
    }
    case "creator/search/characters": {
      return {
        title: createTitle(`キャラクター検索 | クリエイター検索`),
      }
    }
    case "messages": {
      return {
        title: createTitle("メッセージ"),
      }
    }
    case "mypage": {
      return {
        title: createTitle("マイページ"),
      }
    }
    case "mypage/edit-profile": {
      return {
        title: createTitle("プロフィール編集"),
      }
    }
    case "mypage/bookshelf": {
      return {
        title: createTitle("本棚"),
      }
    }
    case "mypage/purchased": {
      return {
        title: createTitle("購入履歴"),
      }
    }
    case "mypage/gift": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "mypage/gift/terms": {
      return {
        title: createTitle("たいあっぷギフト企画参加規約への同意"),
      }
    }
    case "notifications": {
      return {
        title: createTitle("通知"),
      }
    }
    case "deactivate": {
      return {
        title: createTitle("退会について"),
      }
    }
    case "user": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "search/genres": {
      return {
        title: createTitle(`ジャンル検索`),
      }
    }
    case "tieups": {
      return {
        title: createTitle("作品検索結果一覧"),
      }
    }
    case "tieups/sales": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "tieups/sales/completed": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "tieups/sales/error": {
      return {
        title: createTitle("決済エラー"),
      }
    }
    case "creator/novels-notes": {
      return {
        title: createTitle("共有メモ"),
      }
    }
    case "creator/novels-notes-detail": {
      return {
        title: createTitle("共有メモ詳細"),
      }
    }
    case "creator/novels-characters": {
      return {
        title: createTitle("キャラリスト"),
      }
    }
    case "creator/novels-characters-detail": {
      return {
        title: createTitle("キャラクター詳細"),
      }
    }
    case "creator/mypage/series": {
      return {
        title: createTitle("作品詳細"),
      }
    }
    case "tieupDetail": {
      return {
        title: createTitle(`${title}`),
      }
    }
    case "webtoonDetail": {
      return {
        title: createTitle(`${title}`),
      }
    }
    default: {
      return {
        title: createTitle(title),
      }
    }
  }
}

export default siteMeta
