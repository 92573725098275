import clsx from "clsx"
import Link from "next/link"

import routes from "routes"

// hook
import { useFCM } from "src/components/hooks/useFCM"

export const IconNav: React.FC = () => {
  const { unreadNoticeCount } = useFCM()

  return (
    <ul className="global-header-iconNav">
      <li>
        <Link href={routes.notifications()}>
          <a className="global-header-icon -notice">
            <span className="global-header-icon-label sr-only">通知</span>
            <span
              className={clsx({
                "global-header-icon-counter": true,
                "-display": unreadNoticeCount > 0,
              })}
            >
              {unreadNoticeCount < 100 ? unreadNoticeCount : "99+"}
            </span>
          </a>
        </Link>
      </li>
    </ul>
  )
}
