import Head from "next/head"

type Props = {
  description: string
  image: string
  title: string
  twitterCard: "summary" | "summary_large_image"
  type: "article" | "website"
  url: string
  siteName?: string
  fbAppId?: string
  twitterSiteAccount?: string
}

const HTMLHeadForSSR: React.FC<Props> = ({
  description,
  image,
  title,
  twitterCard,
  type,
  url,
  siteName,
  fbAppId,
  twitterSiteAccount,
}) => (
  <Head>
    <meta name="description" content={description} key="description" />
    <meta
      property="og:description"
      content={description}
      key="og:description"
    />
    <meta property="og:image" content={image} key="og:image" />
    <meta property="og:title" content={title} key="og:title" />
    <meta property="og:type" content={type} key="og:type" />
    <meta property="og:url" content={url} key="og:url" />
    <meta name="twitter:card" content={twitterCard} key="twitter:card" />
    {siteName && <meta property="og:site_name" content={siteName} />}
    {fbAppId && <meta property="fb:app_id" content={fbAppId} />}
    {twitterSiteAccount && (
      <meta name="twitter:site" content={twitterSiteAccount} />
    )}
  </Head>
)

export default HTMLHeadForSSR
