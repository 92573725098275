import Link from "next/link"
import React from "react"

import routes from "routes"
import URLUtils from "src/utils/URLUtils"

// hook
import { useUser } from "src/utils/auth/useUser"

export const NavList: React.FC = () => {
  const { isMember } = useUser()

  return (
    <>
      <ul className="global-nav-list01">
        {isMember && (
          <>
            <li>
              <Link href={routes.mypage()}>
                <a className="global-nav-list01-anchor -mypage">マイページ</a>
              </Link>
            </li>
            <li>
              <Link href={routes.bookshelf()}>
                <a className="global-nav-list01-anchor -bookshelf">本棚</a>
              </Link>
            </li>
            <li>
              <Link href={routes.notifications()}>
                <a className="global-nav-list01-anchor -notice">通知</a>
              </Link>
            </li>
            <li>
              <Link href={routes.purchased()}>
                <a className="global-nav-list01-anchor -purchased">購入履歴</a>
              </Link>
            </li>
          </>
        )}
      </ul>
      <ul className="global-nav-list02">
        {isMember ? (
          <>
            <li>
              <Link href={routes.creator()}>
                <a className="global-nav-list02-anchor">パートナーを探す</a>
              </Link>
            </li>
            <li>
              <Link href={routes.mypageGift()}>
                <a className="global-nav-list02-anchor">たいあっぷギフト管理</a>
              </Link>
            </li>
            <li>
              <a
                href={URLUtils.getHelpPage("info/index.html")}
                className="global-nav-list02-anchor"
                target="_blank"
                rel="noreferrer"
              >
                運営からのお知らせ
              </a>
            </li>
            <li>
              <Link href={routes.mypageEditProfile()}>
                <a className="global-nav-list02-anchor">アカウント設定</a>
              </Link>
            </li>
            <li>
              <Link href={routes.creatorMyPageSettingNotifications()}>
                <a className="global-nav-list02-anchor">通知設定</a>
              </Link>
            </li>
            <li>
              <a
                href={URLUtils.getHelpPage("guide/index.html")}
                className="global-nav-list02-anchor"
                target="_blank"
                rel="noreferrer"
              >
                ガイドライン
              </a>
            </li>
            <li>
              <a
                href={URLUtils.getHelpPage("qa/index.html")}
                target="_blank"
                rel="noreferrer"
                className="global-nav-list02-anchor"
              >
                よくある質問
              </a>
            </li>
            <li>
              <a
                href={URLUtils.getHelpPage("terms/index.html")}
                className="global-nav-list02-anchor"
                target="_blank"
                rel="noreferrer"
              >
                利用規約
              </a>
            </li>
            <li>
              <a
                href={URLUtils.getHelpPage("personalinfo/index.html")}
                target="_blank"
                rel="noreferrer"
                className="global-nav-list02-anchor"
              >
                個人情報のお取り扱いについて
              </a>
            </li>
            <li>
              <a
                href={URLUtils.getHelpPage("legal/commerce-law.pdf")}
                target="_blank"
                rel="noreferrer"
                className="global-nav-list02-anchor"
              >
                特定商取引法に基づく表示
                <img
                  className="global-nav-list02-pdf-icon"
                  src="/img/icn_pdf.png"
                  alt="PDFアイコン"
                />
              </a>
            </li>
            <li>
              <a
                href={URLUtils.getHelpPage("legal/ebook-purchase-terms.pdf")}
                target="_blank"
                rel="noreferrer"
                className="global-nav-list02-anchor"
              >
                たいあっぷ商品購入規約
                <img
                  className="global-nav-list02-pdf-icon"
                  src="/img/icn_pdf.png"
                  alt="PDFアイコン"
                />
              </a>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link href={routes.authSignIn()}>
                <a className="global-nav-list02-anchor">ログイン</a>
              </Link>
            </li>
            <li>
              <Link href={routes.authSignUp()}>
                <a className="global-nav-list02-anchor">会員登録</a>
              </Link>
            </li>
          </>
        )}
      </ul>
    </>
  )
}
