/**
 * グローバルヘッダ・フッタ付きの閲覧者向けレイアウトコンポーネント
 */
import GlobalFooter from "src/components/common/GlobalFooter"
import { GlobalHeaderForReader } from "src/components/common/GlobalHeaderForReader"
import { GlobalHeaderLogoOnly } from "src/components/common/GlobalHeaderLogoOnly"
import HTMLHeadForSSR from "src/components/utils/HTMLHeadForSSR"
import { siteInfo } from "src/config/siteMeta"
import URLUtils from "src/utils/URLUtils"

type Props = {
  isShowHeaderLogoOnly?: boolean
  isFooter?: boolean
}

export const DefaultLayoutForReader: React.FC<Props> = ({
  children,
  isShowHeaderLogoOnly = false,
  isFooter = true,
}) => (
  <>
    <HTMLHeadForSSR
      description={siteInfo.description}
      image={URLUtils.getStaticPage(`parts/img/top_og_image.jpg`)}
      title={siteInfo.title}
      twitterCard="summary_large_image"
      type="website"
      url={URLUtils.getRootURLFromEnv()}
    />
    {isShowHeaderLogoOnly ? (
      <GlobalHeaderLogoOnly />
    ) : (
      <GlobalHeaderForReader />
    )}
    {children}
    {isFooter && <GlobalFooter />}
  </>
)
