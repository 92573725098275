import clsx from "clsx"
import Link from "next/link"
import { useUnmount } from "react-use"

import routes from "routes"
import URLUtils from "src/utils/URLUtils"

// hook
import { useUser } from "src/utils/auth/useUser"

// view
import TieupImg from "src/components/common/TieupImg"
import { useMyAvatarSrc } from "../../hooks/useMyAvatarSrc"

type props = {
  visible: boolean
  handleClose: () => void
  handleLogout: () => void
}

export const GlobalPcMenu: React.FC<props> = ({
  visible,
  handleClose,
  handleLogout,
}) => {
  const { apiUser } = useUser()
  const avatarSrc = useMyAvatarSrc()

  useUnmount(() => {
    handleClose()
  })

  return (
    <div
      className={clsx({
        "global-header-avatarDetail": true,
        "is-show": visible,
      })}
    >
      <div className="global-header-avatarDetail-change-button-wrapper">
        <Link href={routes.creator()}>
          <a
            target="_blank"
            className="global-header-avatarDetail-change-button"
          >
            作品を投稿する
          </a>
        </Link>
      </div>
      <button
        className="c-close-button global-header-avatarDetail-close"
        onClick={handleClose}
      >
        <span className="c-close-button-icon"></span>
        <span className="c-close-button-label">閉じる</span>
      </button>
      <div className="global-header-avatarDetail-data">
        <div className="global-header-avatarDetail-image">
          <TieupImg src={avatarSrc} alt={apiUser?.name} />
        </div>
        <div className="global-header-avatarDetail-text">
          {apiUser && (
            <>
              <p className="global-header-avatarDetail-name">{apiUser.name}</p>
              <p className="global-header-avatarDetail-id">
                {apiUser.display_name}
              </p>
            </>
          )}
        </div>
      </div>
      <ul className="global-header-avatarDetail-nav01">
        <>
          <li>
            <Link href={routes.mypage()}>
              <a className="global-header-avatarDetail-nav01-anchor -mypage">
                マイページ
              </a>
            </Link>
          </li>
          <li>
            <Link href={routes.bookshelf()}>
              <a className="global-header-avatarDetail-nav01-anchor -bookshelf">
                本棚
              </a>
            </Link>
          </li>
          <li>
            <Link href={routes.notifications()}>
              <a className="global-header-avatarDetail-nav01-anchor -notice">
                通知
              </a>
            </Link>
          </li>
          <li>
            <Link href={routes.purchased()}>
              <a className="global-header-avatarDetail-nav01-anchor -purchased">
                購入履歴
              </a>
            </Link>
          </li>
        </>
      </ul>
      <ul className="global-header-avatarDetail-nav02">
        <li>
          <Link href={routes.creator()}>
            <a className="global-header-avatarDetail-nav02-anchor">
              パートナーを探す
            </a>
          </Link>
        </li>
        <li>
          <Link href={routes.mypageGift()}>
            <a className="global-header-avatarDetail-nav02-anchor">
              たいあっぷギフト管理
            </a>
          </Link>
        </li>
        <li>
          <a
            href={URLUtils.getHelpPage("info/index.html")}
            target="_blank"
            rel="noreferrer"
            className="global-header-avatarDetail-nav02-anchor"
          >
            運営からのお知らせ
          </a>
        </li>
        <li>
          <Link href={routes.mypageEditProfile()}>
            <a className="global-header-avatarDetail-nav02-anchor">
              アカウント設定
            </a>
          </Link>
        </li>
        <li>
          <Link href={routes.creatorMyPageSettingNotifications()}>
            <a className="global-header-avatarDetail-nav02-anchor">通知設定</a>
          </Link>
        </li>
        <li>
          <a
            href={URLUtils.getHelpPage("guide/index.html")}
            target="_blank"
            rel="noreferrer"
            className="global-header-avatarDetail-nav02-anchor"
          >
            ガイドライン
          </a>
        </li>
        <li>
          <a
            href={URLUtils.getHelpPage("qa/index.html")}
            target="_blank"
            rel="noreferrer"
            className="global-header-avatarDetail-nav02-anchor"
          >
            よくある質問
          </a>
        </li>
        <li>
          <a
            href={URLUtils.getHelpPage("terms/index.html")}
            target="_blank"
            rel="noreferrer"
            className="global-header-avatarDetail-nav02-anchor"
          >
            利用規約
          </a>
        </li>
        <li>
          <a
            href={URLUtils.getHelpPage("personalinfo/index.html")}
            target="_blank"
            rel="noreferrer"
            className="global-header-avatarDetail-nav02-anchor"
          >
            個人情報のお取り扱いについて
          </a>
        </li>
        <li>
          <a
            href={URLUtils.getHelpPage("legal/commerce-law.pdf")}
            target="_blank"
            rel="noreferrer"
            className="global-header-avatarDetail-nav02-anchor"
          >
            特定商取引法に基づく表示
            <img
              className="global-header-avatarDetail-nav02-pdf-icon"
              src="/img/icn_pdf.png"
              alt="PDFアイコン"
            />
          </a>
        </li>
        <li>
          <a
            href={URLUtils.getHelpPage("legal/ebook-purchase-terms.pdf")}
            target="_blank"
            rel="noreferrer"
            className="global-header-avatarDetail-nav02-anchor"
          >
            たいあっぷ商品購入規約
            <img
              className="global-header-avatarDetail-nav02-pdf-icon"
              src="/img/icn_pdf.png"
              alt="PDFアイコン"
            />
          </a>
        </li>
      </ul>
      <button
        className="global-header-avatarDetail-logout"
        onClick={handleLogout}
      >
        ログアウト
      </button>
    </div>
  )
}
